<template>
  <div class="result_cat">
    <div class="result_cat_header d-flex flex-row">
      <div class="result_cat_header_img">
        <img
          :src="require(`@/assets/icons/${category.img_alt}`)"
          width="48"
          height="48"
        />
      </div>
      <div class="align-self-center">
        <div class="result_cat_name">{{ $t(category.label_alt) }}</div>
        <div class="result_cat_cost" v-html="cost"></div>
      </div>
    </div>
    <hr />

    <div v-if="numbers.length != 0">
      <div class="row no-gutters">
        <div
          class="col-6"
          :class="{ 'pl-1': index % 2 != 0, 'pr-1': index % 2 == 0 }"
          v-for="(item, index) in numbersToDisplay"
          :key="`${category.id}_${item.MSISDN}`"
        >
          <CategoryResultItem
            :msisdn="item.MSISDN"
            :categoryId="category.id"
            :mask="outputMask"
            :searchType="filterParams.searchType"
            :optNumber="optNumber"
          />
        </div>
      </div>

      <div class="mt-2 text-right" v-if="!showButton">
        <a
          class="result_cat_show_more"
          :class="{ disabled: disabledShowMoreBtn }"
          @click="showMoreNumbers()"
          >{{ $t("results.btn_show_more") }}</a
        >
      </div>

      <div class="mt-2" v-if="showButton">
        <button
          type="button"
          :disabled="disabledShowMoreBtn"
          class="btn btn-show-more btn-block"
          @click="showMoreNumbers()"
        >
          {{ $t("results.btn_show_more_alt") }}
        </button>
      </div>
    </div>
    <div class="search_choice_header text-left" v-if="numbers.length == 0">
      {{ $t("search.class_no_results") }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CategoryResultItem from "./CategoryResultItem";
import helperMixin from "@/mixins/helper.mixin";
import displayLimits from "@/utils/displayLimits";

export default {
  name: "CategoryResult",
  mixins: [helperMixin],
  props: {
    classId: Number,
    showButton: Boolean,
    numbers: Array,
  },
  data: () => ({
    displayLimits: displayLimits,
  }),
  computed: {
    ...mapGetters(["numberClass", "filterParams", "limitData"]),
    category() {
      return this.numberClass(this.classId);
    },
    numbersToDisplay() {
      return this.numbers.slice(0, this.limitData[this.classId]);
    },
    cost() {
      let price = this.formatPrice(this.category.cost);
      if (this.classId == 1) return this.$t("results.free");
      else if (this.classId == 4) return `от ${price} <span>c</span>`;
      else return `${price} <span>c</span>`;
    },
    disabledShowMoreBtn() {
      return this.limitData[this.classId] >= this.numbers.length;
    },
    outputMask() {
      return this.filterParams.mask;
    },
    optNumber() {
      const cloneParams = Object.assign({}, this.filterParams);
      if (cloneParams.searchType == "favnum") {
        return cloneParams.favParams.value;
      } else {
        let numberPieces = cloneParams.numberParams.numberPieces;
        let p1 = numberPieces.p1 == "" ? "*" : numberPieces.p1;
        let p2 = numberPieces.p2 == "" ? "*" : numberPieces.p2;
        let p3 = numberPieces.p3 == "" ? "*" : numberPieces.p3;
        let p4 = numberPieces.p4 == "" ? "*" : numberPieces.p4;
        let p5 = numberPieces.p5 == "" ? "*" : numberPieces.p5;
        let p6 = numberPieces.p6 == "" ? "*" : numberPieces.p6;

        return `${p1}${p2}${p3}${p4}${p5}${p6}`;
      }
    },
  },
  methods: {
    ...mapMutations(["SET_LIMIT_DATA"]),
    showMoreNumbers() {
      let incRows =
        this.limitData[this.classId] + this.displayLimits.INC_STEP_MOB;
      this.SET_LIMIT_DATA({ classId: this.classId, count: incRows });
    },
  },
  created() {
    if (!this.limitData[this.classId])
      this.SET_LIMIT_DATA({
        classId: this.classId,
        count: this.displayLimits.LIMIT_ITEMS_MOB,
      });
  },
  components: {
    CategoryResultItem,
  },
};
</script>

<style>
.result_cat {
  padding-bottom: 24px;
}
.result_cat hr {
  border-top: 1px solid rgba(30, 30, 30, 0.05);
}
.result_cat_header_img {
  margin-right: 6px;
}
.result_cat_name {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 2px;
}
.result_cat_cost {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}
.result_cat_cost span {
  font-weight: 400;
  text-decoration: underline;
}
.result_cat_show_more {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration-line: underline;
  color: #71c343;
}
.result_cat_show_more:hover {
  color: #71c343;
}
.result_cat_show_more.disabled {
  pointer-events: none;
  color: rgba(30, 30, 30, 0.5);
}
</style>