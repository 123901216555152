<template>
  <div>
    <div class="row row-cols-2 no-gutters" v-if="!!this.showCats">
      <div
        class="col"
        v-for="(cl, index) in numberClasses"
        :key="cl.id"
        :class="{ 'pl-1': index % 2 != 0, 'pr-1': index % 2 == 0 }"
      >
        <div class="search_choice_radio_btn">
          <input
            :id="`checkbox-${index}`"
            type="radio"
            name="searchClass"
            :value="cl.id"
            v-model="resultClass"
          />
          <label :for="`checkbox-${index}`" class="d-flex align-items-center">
            <div>
              <img
                :src="require(`@/assets/icons/${cl.img}`)"
                width="32"
                height="32"
              />
            </div>
            <div class="choice_checkbox_btn_label">
              {{ $t(cl.label) }}
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import NumberClasses from "@/utils/numberClasses";
import {mapActions, mapGetters, mapMutations} from "vuex";
import displayLimits from "@/utils/displayLimits";

export default {
  name: "NumberClasses",
  props: ["classes", "foundClasses", "showCats"],
  data: () => ({
    searchClass: [1, 2],
    resultClass: null,
    numberClasses: NumberClasses,
    displayLimits: displayLimits
  }),
  computed: {
    ...mapGetters(["filterParams"]),
    ...mapGetters([
      "filterParams",
      "numbersByClass",
      "searchResults",
      "limitData",
      "selectedClassId",
      "showMoreButton",
      "currentPage"
    ]),
  },
  watch: {
    searchClass: function (value) {
      this.$emit("change-search-class", value);
    },

    resultClass: async function (value) {

      this.SET_SELECTED_CLASS_ID(value);

      if (!this.limitData[value])
        this.SET_LIMIT_DATA({
          classId: value,
          count: this.displayLimits.LIMIT_ITEMS,
        });

      if(!this.noUpdate) {
        this.SET_PAGE(1);
        this.$loading(true);
        try {
          await this.fetchSearchedNumbers({
            classId: value || 2,
            limitItems: this.displayLimits.LIMIT_ITEMS,
          });
          this.$loading(false);
        }
        catch (e) {
          this.$loading(false);
        }

      }
      this.SET_NO_UPDATE(true);

    },

    selClassId: function (value) {
      this.resultClass = value;
      if (value == null) {
        if (this.foundClasses.length > 0) {
          this.resultClass = this.foundClasses[0].id;
          this.SET_SELECTED_CLASS_ID(this.resultClass);
          if (!this.limitData[this.resultClass])
            this.SET_LIMIT_DATA({
              classId: this.resultClass,
              count: this.displayLimits.LIMIT_ITEMS,
            });
        }
      }


    },
  },
  methods: {
    ...mapActions(["fetchSearchedNumbers"]),
    sortClassesArray(array) {
      return orderBy(
        array,
        function (o) {
          return o.order;
        },
        "asc"
      );
    },
    ...mapMutations(["SET_LIMIT_DATA", "SET_SELECTED_CLASS_ID", "SET_PAGE", "SET_NO_UPDATE"]),
  },
  created() {

    if (this.selectedClassId) {
      this.resultClass = this.selectedClassId;
    } else {
      if (this.numberClasses.length > 0) {
        this.resultClass = (this.numberClasses.find(v => +v.id === 1)||  this.numberClasses[0]).id;
      }
    }

    /*
    if (this.filterParams.classes) {
      this.searchClass = this.filterParams.classes;
    }
    this.$emit("change-search-class", this.searchClass);

     */
  },
};
</script>

<style>
.search_choice_radio_btn input[type="radio"] {
  display: none;
}
.search_choice_radio_btn label {
  display: inline-block;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 3px 8px;
  border-radius: 8px;
  user-select: none;
  opacity: 1;
  width: 100%;
  max-width: 250px;
  text-align: center;
  border: 1px solid rgba(30, 30, 30, 0.15);
}
.search_choice_radio_btn label div {
  display: inline;
}
.search_choice_radio_btn {
  margin-left: 7px;
}
.search_choice_radio_btn input[type="radio"]:checked + label {
  opacity: 1;
  border: 1px solid #71c343;
}

.search_choice_radio_btn label div {
  opacity: 1;
}

.search_choice_radio_btn input[type="radio"]:checked + label {
  /* opacity: 1; */
  background: #ffffff;
  border: 1px solid #71c343;
}

.search_choice_radio_btn label:hover {
  cursor: pointer;
}
</style>
