<template>
  <div class="search-choices-wrapper">
    <div class="row no-gutters justify-content-md-center">
      <div class="col">
        <div class="search-choices_radio_btn">
          <input
            id="radio-1"
            type="radio"
            name="searchType"
            value="number"
            v-model="searchType"
          />
          <label for="radio-1" class="d-flex align-items-center">
            <div>
              <img src="@/assets/icons/choice1.png" width="18" height="24" />
            </div>
            <div class="choice_radio_btn_label">{{ $t("search.choice1") }}</div>
          </label>
        </div>
      </div>
      <div class="col ml-2">
        <div class="search-choices_radio_btn">
          <input
            id="radio-2"
            type="radio"
            name="searchType"
            value="favnum"
            v-model="searchType"
          />
          <label for="radio-2" class="d-flex align-items-center">
            <div>
              <img src="@/assets/icons/choice2.png" width="24" height="24" />
            </div>
            <div class="choice_radio_btn_label">{{ $t("search.choice2") }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchChoices",
  data: () => ({
    searchType: "number",
  }),
  computed: {
    ...mapGetters(["filterParams"]),
  },
  watch: {
    searchType: function (value) {
      this.$emit("change-search-type", value);
    },
  },
  created() {
    if (this.filterParams.searchType) {
      this.searchType = this.filterParams.searchType;
    }
  },
};
</script>

<style>
.search-choices-wrapper {
  margin-top: 24px;
}
.search-choices_radio_btn input[type="radio"] {
  display: none;
}
.search-choices_radio_btn label {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  min-height: 58px;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 11px 8px;
  border-radius: 8px;
  user-select: none;
  background: #fff;
  opacity: 0.5;

  text-align: center;
}
.search-choices_radio_btn label div {
  display: inline;
}
.choice_radio_btn_label {
  margin-left: 7px;
}
.search-choices_radio_btn input[type="radio"]:checked + label {
  opacity: 1;
  border: 1px solid #71c343;
}
</style>