<template>
  <mobile-layout>
    <main class="main-page__body-m container">
      <div class="page__body-header-m">{{ $t("search.header") }}</div>
      <SearchChoices @change-search-type="changeSearchType" />

      <div class="search-choices container mx-0 px-0">
        <transition name="component-fade" mode="out-in">
          <component
            :is="searchTypeComponent"
            @change-search-number="changeSearchNumber"
          ></component>
        </transition>
      </div>

      <div>
        <button
            type="button"
            class="btn btn-accept-filter btn-block"
            :disabled="acceptFilterDisabled"
            @click="checkCaptcha()"
        >
          {{ $t("search.btn") }}
        </button>
      </div>
      <div class="search-classes">
        <NumberClasses
            @change-search-class="changeSearchClass"
            :found-classes="selectedClasses"
            :show-cats="this.showCats"
        />
      </div>

      <MaskNumber
        class="mt-3"
        v-if="selSearchType == 'favnum'"
        @change-mask="changeNumberMask"
      />

      <div class="search-results" id="search-results">
        <transition name="component-fade" mode="out-in">
          <component :is="searchResultComponent"></component>
        </transition>
        <div class="pb-3" v-if="showResults && searchResults.length == 0">
          {{ $t("search.no_results") }}
        </div>
      </div>
    </main>
  </mobile-layout>
</template>

<script>
import {orderBy} from "lodash";

// eslint-disable-next-line no-unused-vars
let reCaptcha = null;

if(process.env.VUE_APP_CAPTCHA) {
  reCaptcha = new Promise((resolve, reject) => {
    const $script = document.createElement("script");
    $script.src =
        "https://www.google.com/recaptcha/api.js?render=6Lfp-aEUAAAAAMYdMocMLADFakrKaqtC1jEjbZGV";
    resolve(document.head.appendChild($script));

    setTimeout(
        () => reject(new Error("Google reCaptcha не инициализирована")),
        3000
    );
  });
}

let VueScrollTo = require("vue-scrollto");
let options = {
  container: "body",
  easing: "ease-in-out",
  lazy: false,
  offset: -30,
  force: true,
  cancelable: true,
  x: false,
  y: true,
};

import MobileLayout from "@/layouts/MobileLayout";
import SearchChoices from "@/components/SearchChoices.vue";
import SearchNumberChoice from "@/components/SearchNumberChoice.vue";
import SearchFavChoice from "@/components/SearchFavChoice.vue";
import NumberClasses from "@/components/NumberClasses.vue";
import SearchResults from "@/components/SearchResults.vue";
import SearchResultsEmpty from "@/components/SearchResultsEmpty.vue";
import MaskNumber from "@/components/MaskNumber.vue";
import displayLimits from "@/utils/displayLimits";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "NumberSearchMobile",
  data: () => ({
    displayLimits: displayLimits,
    filter: {},
    selSearchType: "number",
    selClasses: [],
    numberParams: {},
    favParams: { value: "", code: "-1" },
    mask: {},
    showCats: false
  }),
  computed: {
    ...mapGetters(["filterParams", "showResults", "searchResults"]),
    acceptFilterDisabled() {
      return false;
      /*
      if (
        this.selClasses.length == 0 ||
        (this.selSearchType == "favnum" && this.favParams.value.length < 2)
      )
        return true;
      else return false;

       */
    },
    selectedClasses() {
      const orderedClasses = orderBy(
          this.numberClasses,
          function (o) {
            return o.order;
          },
          "asc"
      );

      return orderedClasses.filter((elm1) => {
        return (this.filterParams.classes ?? []).find((elm2) => elm2 === elm1.id);
      });
    },
    searchTypeComponent() {
      let type = this.filterParams.searchType
        ? this.filterParams.searchType
        : this.selSearchType;
      if (type == "number") return SearchNumberChoice;
      else return SearchFavChoice;
    },
    searchResultComponent() {
      if (this.showResults && this.searchResults.length != 0) return SearchResults;
      else return SearchResultsEmpty;
    },
  },
  methods: {
    ...mapMutations([
      "SET_FILTER_PARAMS",
      "SET_SHOW_RESULTS",
      "SET_FILTER_SEARCH_TYPE",
      "SET_PAGE",
      "SET_NO_UPDATE",
      "SET_SELECTED_CLASS_ID"
    ]),
    ...mapGetters([ "selectedClassId" ]),
    ...mapActions(["fetchSearchedNumbers"]),
    changeSearchType(value) {
      this.selSearchType = value;
      this.SET_FILTER_SEARCH_TYPE(value);
    },
    changeSearchNumber(value) {
      if (this.selSearchType == "number") {
        this.numberParams = value;
        this.mask = value.mask;
      } else {
        this.favParams = value;
      }
    },
    changeSearchClass(value) {
      this.selClasses = value;
    },
    changeNumberMask(value) {
      this.mask = value;
    },
    checkCaptcha() {
      let _this = this;
      this.$loading(true);

      //if(process.env.VUE_APP_CAPTCHA ) {
      //  reCaptcha.then(
      //      () => {
      //        setTimeout(() => {
      //          window.grecaptcha.ready(function () {
      //            window.grecaptcha
      //                .execute("6Lfp-aEUAAAAAMYdMocMLADFakrKaqtC1jEjbZGV", {
      //                  action: "pick_number",
      //                })
      //                .then(function (token) {
      //                  _this.acceptFilter(token);
      //                });
      //          });
      //        }, 1000);
      //      },
      //      (error) => {
      //        console.log(error);
      //      }
      //  );
      //}
      //else

        setTimeout(() => {
          _this.acceptFilter(null);
        }, 1000);


      /*
      // _this.acceptFilter("token");
      reCaptcha.then(
        () => {
          setTimeout(() => {
            window.grecaptcha.ready(function () {
              window.grecaptcha
                .execute("6Lfp-aEUAAAAAMYdMocMLADFakrKaqtC1jEjbZGV", {
                  action: "pick_number",
                })
                .then(function (token) {
                  _this.acceptFilter(token);
                });
            });
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
      */
    },
    async acceptFilter(token) {
      this.filter = {
        classes: this.selClasses,
        searchType: this.selSearchType,
        numberParams: this.numberParams,
        favParams: this.favParams,
        mask: this.mask,
      };
      this.SET_FILTER_PARAMS(this.filter);

      try {
        this.SET_PAGE(1);
        this.SET_SELECTED_CLASS_ID(this.selectedClassId() ?? 1);
        this.showCats = true;

        await this.fetchSearchedNumbers({
          token: token,
          ismob: 1,
          limitItems: this.displayLimits.LIMIT_ITEMS_MOB,
        });

        this.$loading(false);
        if (this.searchResults.length > 0)
          VueScrollTo.scrollTo("#search-results", 1000, options);
      } catch (error) {
        console.error(error);
        this.$loading(false);
      }
    },
  },
  components: {
    MobileLayout,
    SearchChoices,
    SearchNumberChoice,
    SearchFavChoice,
    NumberClasses,
    SearchResults,
    MaskNumber,
  },
};
</script>
