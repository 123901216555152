<template>
  <div class="col-sm-12 col-md-6 offset-md-3 p-0">
    <div class="search_choice_header">
      {{ $t("search.choice1_header") }}
    </div>
    <div class="search_number_wrapper d-flex justify-content-center">
      <div class="d-flex flex-row position-relative" style="flex-shrink: 0">
        <div class="dropdown search_number_code_dropdown">
          <button
            class="btn dropdown-toggle shadow-none"
            type="button"
            id="dropdownMenuNumberCode"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ selectedNumberCode }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuNumberCode">
            <a
              class="dropdown-item"
              :class="{ is_new_class: code.is_new }"
              v-for="code in numberCodes"
              :key="code.value"
              @click="setNumberCode(code.value)"
              >{{ code.label }}</a
            >
          </div>
        </div>
        <div class="number_pieces d-inline-block">
          <input
            id="number_pieces_1"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p1"
            @keyup="focusNext"
          />
          <input
            id="number_pieces_2"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p2"
            @keyup="focusNext"
          />
          <hr v-if="mask.value == '2'" />
          <input
            id="number_pieces_3"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p3"
            @keyup="focusNext"
          />
          <hr v-if="mask.value == '1'" />
          <input
            id="number_pieces_4"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p4"
            @keyup="focusNext"
          />
          <hr v-if="mask.value == '2'" />
          <input
            id="number_pieces_5"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p5"
            @keyup="focusNext"
          />
          <input
            id="number_pieces_6"
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            placeholder="X"
            maxlength="1"
            v-model="numberPieces.p6"
            @keyup="focusNext"
          />
        </div>
        <div class="mask_number_d">
          <MaskNumber @change-mask="changeNumberMask" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberCodes from "@/utils/numberCodes";
import MaskNumber from "@/components/MaskNumber.vue";
import { mapGetters } from "vuex";

export default {
  name: "SearchNumberChoice",
  data: () => ({
    numberCodes: NumberCodes,
    code: "-1",
    mask: {},
    numberPieces: { p1: "", p2: "", p3: "", p4: "", p5: "", p6: "" },
  }),
  computed: {
    ...mapGetters(["filterParams", "promo995"]),
    selectedNumberCode: function () {
      if (this.code == "-1") return this.$t("search.all_codes");
      else return "0" + this.code;
    },
    buildNumber: function () {
      return {
        code: this.code,
        mask: this.mask,
        numberPieces: this.numberPieces,
      };
    },
  },
  watch: {
    buildNumber: function (value) {
      this.$emit("change-search-number", value);
    },
  },
  methods: {
    setNumberCode: function (code) {
      this.code = code;
    },
    changeNumberMask(value) {
      this.mask = value;
    },
    focusNext(event) {
      let targetId = Number(event.target.id.slice(-1));
      if (event.keyCode !== 13) {
        this.numberPieces[`p${targetId}`] = event.key.replace(/[^0-9]/g, "");
      }

      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        if (targetId <= 5) {
          const input = document.getElementById(`number_pieces_${targetId + 1}`);
          input.focus();
          input.setSelectionRange(0, 1);
          input.classList.add("empty");
        }
        event.target.classList.remove("empty");
      } else if (event.keyCode == 8) {
        if (event.target.classList.contains("empty") && targetId !== 1) {
          const inputBs = document.getElementById(`number_pieces_${targetId - 1}`);
          inputBs.focus();
          inputBs.setSelectionRange(0, 1);
        } else {
          if (event.target.value.length === 0) {
            event.target.classList.add("empty");
          }
        }
      }

      return;
    },
  },
  created() {
    if (this.filterParams.numberParams) {
      this.code = this.filterParams.numberParams.code;
      this.numberPieces = this.filterParams.numberParams.numberPieces;
    }
    this.$emit("change-search-number", this.buildNumber);
  },
  components: {
    MaskNumber,
  },
};
</script>

<style>
.search_number_wrapper {
  margin-top: 16px;
  margin-bottom: 64px;
}
.number_pieces {
  margin-left: 6px;
}
.number_pieces input {
  width: 36px;
  height: 48px;
  display: inline-block;
  margin: 0 2px;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
  border: 0.5px solid rgba(30, 30, 30, 0.5);
  border-radius: 4px;
}
.number_pieces input:focus {
  border-color: #71c343;
}
.number_pieces input::placeholder {
  font-weight: 300 !important;
}
.number_pieces hr {
  display: inline-block;
  width: 5px;
  margin: 0;
  border-top: 1px solid rgba(30, 30, 30, 0.5);
  vertical-align: middle;
}

@media (max-width: 320px) {
  .number_pieces input {
    width: 31px;
    padding: 10px 9px;
  }
  .search_number_code_dropdown {
    width: 62px;
    padding: 13.5px 4px;
  }
}
</style>