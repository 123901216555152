<template>
  <div class="d-flex result_cat_item mb-2">
    <div class="result_cat_item_msisdn w-100" v-html="formattedMsisdn"></div>
    <div class="flex-shrink-1">
      <button
        type="button"
        class="btn result_cat_item_btn shadow-none p-0 d-block"
        @click="changeNumber()"
      ></button>
    </div>
  </div>
</template>

<script>
import helperMixin from "@/mixins/helper.mixin";

export default {
  name: "CategoryResultItem",
  mixins: [helperMixin],
  props: {
    msisdn: String,
    categoryId: Number,
    mask: Object,
    searchType: String,
    optNumber: String,
  },
  computed: {
    formattedMsisdn() {
      return this.formatMsisdnByMask(
        this.msisdn,
        this.mask.value,
        this.searchType,
        this.optNumber
      );
    },
  },
  methods: {
    changeNumber() {
      this.$router.push({
        name: "NumberInfoMobile",
        params: { msisdn: this.msisdn, categoryId: this.categoryId },
      });
    },
  },
};
</script>

<style>
@media (max-width: 320px) {
  .result_cat_item_msisdn {
    font-size: 14px;
    letter-spacing: 0.05em;
  }
}
</style>