<template>

  <div
      class="col-6 pr-1"
  >
  <div class="d-flex result_cat_item mb-2">
    <div class="result_cat_item_msisdn w-100" v-html="formattedMsisdn"></div>
    <div class="flex-shrink-1">
      <button
        type="button"
        class="btn result_cat_item_btn shadow-none p-0 d-block"
        @click="detailInfo()"
      ></button>
    </div>
  </div>
  </div>
</template>

<script>
import ModalPlatinaComponent from "@/components/desktop/ModalPlatinaComponent.vue";
import helperMixin from "@/mixins/helper.mixin";
import { mapGetters } from "vuex";

export default {
  name: "ClassesResultItem",
  mixins: [helperMixin],
  props: {
    info: Object,
    categoryId: Number,
    mask: Object,
    searchType: String,
    optNumber: String,
  },
  computed: {
    ...mapGetters(["numberClass"]),
    restrictedClass() {
      const restrictedClasses = ["48", "47", "46", "45"];
      if (restrictedClasses.includes(this.info.NCLS_ID)) {
        return true;
      }
      return false;
    },
    category() {
      return this.numberClass(this.categoryId);
    },
    formattedMsisdn() {
      return this.formatMsisdnByMask(
        this.info.MSISDN,
        this.mask.value,
        this.searchType,
        this.optNumber
      );
    },
  },
  methods: {
    detailInfo() {
      if (this.restrictedClass) {
        const data = {
          msisdn: this.info.MSISDN,
          category: this.category,
          price: this.formatPrice(this.info.CATEGORY_PRICE),
        };

        this.$modal.show(
          ModalPlatinaComponent,
          {
            info: data,
          },
          {
            name: "PlatinaClassModal",
            height: "auto",
            width: "375px",
            adaptive: true,
            styles: "transition-delay: 0.2s",
          }
        );
      } else {
        this.$router.push({
          name: "NumberInfoMobile",
          params: { msisdn: this.info.MSISDN, categoryId: this.categoryId },
        });
      }
    },
  },
};
</script>

<style>
.result_cat_item_d {
  width: 162px;
  padding: 8px 6px;
  margin-bottom: 24px;
}
</style>
