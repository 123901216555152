<template>
  <div>
    <div class="search_choice_header">{{ $t("search.choice2_header") }}</div>
    <div class="d-flex justify-content-center mt-3 mb-3">
      <div class="d-flex flex-row">
        <div class="dropdown search_number_code_dropdown">
          <button
            class="btn dropdown-toggle shadow-none"
            type="button"
            id="dropdownMenuNumberCode2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ selectedNumberCode }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuNumberCode2">
            <a
              class="dropdown-item"
              :class="{ is_new_class: code.is_new }"
              v-for="code in numberCodes"
              :key="code.value"
              @click="setNumberCode(code.value)"
              >{{ code.label }}</a
            >
          </div>
        </div>
        <div class="fav_number_input">
          <input
            class="form-control shadow-none"
            type="text"
            inputmode="tel"
            maxlength="4"
            v-model="favNumber"
            @keyup="onKeyUp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberCodes from "@/utils/numberCodes";
import { mapGetters } from "vuex";

export default {
  name: "SearchFavChoice",
  data: () => ({
    numberCodes: NumberCodes,
    code: "-1",
    favNumber: "",
  }),
  computed: {
    ...mapGetters(["filterParams"]),
    selectedNumberCode: function () {
      if (this.code == "-1") return this.$t("search.all_codes");
      else return "0" + this.code;
    },
    buildNumber: function () {
      return {
        code: this.code,
        value: this.favNumber,
      };
    },
  },
  watch: {
    buildNumber: function (value) {
      this.$emit("change-search-number", value);
    },
  },
  methods: {
    setNumberCode: function (code) {
      this.code = code;
    },
    onKeyUp(event) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
  },
  created() {
    if (this.filterParams.favParams) {
      this.favNumber = this.filterParams.favParams.value;
      this.code = this.filterParams.favParams.code;
    }
    this.$emit("change-search-number", this.buildNumber);
  },
};
</script>

<style>
.fav_number_input input {
  width: 168px;
  text-align: center;
  margin-left: 8px;
  padding: 10px 12px;
  height: 48px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
  border: 0.5px solid rgba(30, 30, 30, 0.5);
  border-radius: 4px;
  letter-spacing: 0.08em;
}
.fav_number_input input:focus {
  border-color: #71c343;
}
</style>
